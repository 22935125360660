.cover {
    position: relative;
    z-index: 1;
    background-color: $color-white;

    &:before,
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: calc(100% - 60px);
        background-color: $color-light;
        z-index: -1;
    }

    &:before {
        left: 50%;
        transform: translateX(-50%);
        width: 1710px;
        border-radius: 400px 0px 0px 0px;
    }

    &:after {
        left: calc((100% + 1170px) / 2);
        width: 2000px;
    }

    &__content {
        padding-bottom: 60px; 
        
        > .container {
            display: flex;
            align-items: center;
        }
    }

    &__image-wrapper {
        width: 800px;
        min-height: 500px;
        background: $color-bg--image;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
        flex: none;
        border-radius: $border-radius;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            border-radius: $border-radius;
        }
    }

    &__informations {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 100px 0 0 0;
        margin-right: 40px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &:before,
        &:after {
            height: calc(100% - 30px);
        }

        &:before {
            border-radius: 340px 0px 0px 0px;
            width: 1400px;
        }

        &:after {
            left: calc((100% + 940px) / 2);
        }

        &__image-wrapper {
            min-height: 465px;
            width: 640px;
        }

        &__informations {
            padding: 80px 0 20px;
            margin-right: 30px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .cover {
        &:before,
        &:after {
            height: calc(100% - 50px);
        }

        &:before {
            border-radius: 400px 0px 0px 0px;
            width: 1095px;
        }

        &:after {
            left: calc((100% + 620px) / 2);
        }

        &__content {
            > .container {
                flex-direction: column-reverse;
                align-items: flex-start;
            }
        }

        &__image-wrapper {
            height: 380px;
            width: 100%;
            min-height: 0;
        }

        &__informations {
            padding: 0;
            margin-right: 0;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &::before {
            border-radius: 0;
        }

        &::after {
            display: none;
        }

        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 255px;
        }
    }

}
