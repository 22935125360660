.front-page {
    h2 {
        font-size: 3.125rem;
        text-transform: uppercase;
        margin: 0 0 30px;
        line-height: 1;

        &:after {
            display: none;
        }

        span {
            display: block;
            font-weight: $font-weight-normal;
            font-size: 1.875rem;
            line-height: 1;
        }
    }
}

.home-access-alu {
    position: relative;
    padding-bottom: 60px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: calc(100% - 60px);
        background-color: $color-light;
        z-index: -1;
    }

    &:before {
        left: 50%;
        transform: translateX(-50%);
        width: 1710px;
        border-radius: 400px 0px 0px 0px;
    }

    &:after {
        left: calc((100% + 1170px) / 2);
        width: 2000px;
    }

    > .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
    border-radius: $border-radius;
    overflow: hidden;

    > .container {
        position: relative;
    }

    &__nav {
        &-prev,
        &-next {
            bottom: 50px;
            left: auto;
            top: auto;
            height: auto;
            transition: $duration;

            svg {
                height: 32px;
                width: auto;
                fill: $color-white;
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
            }

            &::after {
                display: none;
            }
        }

        &-prev {
            right: 110px;

            &:hover {
                right: 100px;
            }
        }

        &-next {
            right: 45px;

            &:hover {
                right: 35px;
            }
        }
    }

    .slideshow {
        height: 565px;
        width: 800px;

        img, video {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: top;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            overflow: hidden;

            &:hover {
                color: $color-text;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            left: 0px;
            bottom: 0;
            width: 100%;
            height: auto;
            min-height: 125px;
            padding: 25px 200px 25px 30px;
            color: $color-black;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: $gradient-green;
                opacity: .9;
            }
        }

        &__title {
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;
            font-size: $font-size--4;
            margin: 0 0 5px;
            z-index: 1;
        }

        &__description {
            z-index: 1;
        }

        &--video {
            img {
                display: none;
            }
        }
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================
.home-access {
    width: calc(100% - 800px);

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__link {
        margin: 30px 30px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        &:hover {
            .home-access {
                &__label {
                    color: $color-text;

                    &:before {
                        width: 100%;
                        height: 100%;
                        border-radius: $border-radius;
                    }
                }
            }
        }
    }

    &__svg-container {
        width: 50px;
        height: 60px;
    }

    &__label {
        margin-top: 12px;
        position: relative;
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        color: $color-text;
        text-transform: uppercase;
        line-height: 1.25rem;
        padding: 10px 11px 10px;
        width: 130px;
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            height: 2px;
            width: 43px;
            background-color: $color-main;
            transition: .35s;
            z-index: -1;
        }
    }
}

//======================================================================================================
// Actualités
//======================================================================================================
.home-posts {
    position: relative;
    box-shadow: $shadow--variant;
    padding: 60px 0;

    &__header {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
    }

    &__btn-prev {
        left: 0;

        &:hover {
            left: 5px;
        }
    }
    
    &__btn-next {
        right: 0;

        &:hover {
            right: 5px;
        }
    }

    &__btn-prev,
    &__btn-next {
        transition: $duration;

        &:after {
            display: none;
        }

        &:hover {

            svg {
                fill: $color-third;
            }

        }

        svg {
            fill: $color-fourth;
            width: 29px;
            height: 26px;
            transition: $duration;
        }
    }

    &__title {
        text-align: center;
    }
    
    &__container {
        margin-top: 10px;
    }

    .featured-post {
        &__link {
            &:hover {
                color: $color-text;

                .featured-post {
                    &__title {
                        &:after {
                            width: 100px;
                        }
                    }

                    &__image-container {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__image-container {
            width: 100%;
            height: 220px;
            border-radius: $border-radius;
            overflow: hidden;
            background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat center $color-gray;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
                transform: scale(1);
                transition: .3s;
            }
        }

        &__content {
            padding: 20px 0 10px;
        }

        &__title {
            font-size: $font-size--4;
            margin: 0 0 10px;

            &:after {
                content: '';
                width: 43px;
                height: 2px;
                background-color: $color-third;
                display: block;
                margin-top: 10px;
                transition: $duration;
            }
        }
    }

    &__template-btn {
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;
        margin-top: 30px;
    }

    &__btn {
        margin: 0 15px;
        width: 270px;

        &:last-child {
            background-color: $color-fourth;
            border-color: $color-fourth;
            color: $color-white;

            &:hover {
                color: $color-black;
                background-color: $color-white;
            }
        }
    }
}

//======================================================================================================
// Evènements
//======================================================================================================
.home-events {
    position: relative;
    padding: 60px 0;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        background-color: $color-light;
        height: calc(100% - 80px);
        z-index: -1;
    }

    &:before {
        left: 50%;
        transform: translateX(-50%);
        width: 1710px;
        border-radius: 0px 0px 0px 400px;
    }

    &:after {
        left: calc((100% + 1170px) / 2);
        width: 2000px;
    }

    &__title {
        text-align: center;
    }

    &__container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 500px;
    }

    .featured-event {
        position: relative;
        overflow: hidden;
        display: flex;
        margin-bottom: 30px;
        width: 570px;

        &:hover {
            color: $color-text;
        }

        &__image-container {
            width: 100%;
            height: 100%;
            background: url(/wp-content/themes/noyau/assets/images/icon-events.png) no-repeat center $color-gray;
            flex: none;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
                transform: scale(1);
                transition: .45s;
            }
        }

        &--big {
            height: 465px;
            border-radius: $border-radius;
            box-shadow: $shadow--variant;
            margin-right: 30px;

            &:hover {
                .featured-event {
                    &__image-container {
                        img {
                            transform: scale(1.1);
                        }
                    }

                    &__date {
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }

            .featured-event {
                &__title {
                    color: $color-black;
                    margin: 0 0 5px;
                }

                &__content {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 60px 30px 35px;
                    width: 100%;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: $gradient-green-varaint;
                        opacity: .9;
                        transition: .35s;
                    }
                }

                &__infos {
                    position: relative;
                    z-index: 1;
                }

                &__date {
                    position: absolute;
                    z-index: 1;
                    top: -27px;
                    left: 30px;
                    height: 55px;
                    background-color: $color-white;
                    box-shadow: $shadow--variant;
                    border-radius: $border-radius;
                    padding: 0 20px;
                    font-size: 1.875rem;
                    line-height: 2.125rem;
                    transition: .65s;

                    > svg {
                        transform: rotate(-90deg);
                        fill: $color-second;
                        margin: 0 12px;
                    }

                    &-slash {
                        color: $color-second;
                        line-height: 2.125rem;
                    }
                }
            }
        }

        &--small {
            height: 135px;

            &:hover {
                .featured-event {
                    &__title {
                        &:after {
                            width: 100px;
                        }
                    }

                    &__date {
                        background-color: $color-main;
                        color: $color-text;

                        &-slash {
                            color: $color-text;
                        }

                        > svg {
                            fill: $color-text;
                        }
                    }
                }
            }

            .featured-event {
                &__title {
                    margin: 0 0 10px;

                    &:after {
                        content: '';
                        width: 43px;
                        height: 2px;
                        background-color: $color-main;
                        display: block;
                        margin-top: 8px;
                        transition: $duration;
                    }
                }

                &__date {
                    width: 135px;
                    height: 135px;
                    flex-direction: column;
                    flex: none;
                    border-radius: $border-radius;
                    background-color: $color-second;
                    color: $color-white;
                    font-size: 2.5rem;
                    line-height: 2.75rem;
                    transition: .45s;

                    > svg {
                        width: 15px;
                        margin: 8px 0;
                        transition: .4s;
                    }

                    &-slash {
                        color: $color-main;
                        line-height: 2.75rem;
                        transition: .4s;
                    }
                }

                &__content {
                    display: flex;
                }

                &__infos {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 0 0 30px;
                }
            }
        }

        &__date {
            font-family: $font-family--heading;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            > svg {
                width: 11px;
                height: 11px;
                fill: $color-white;
                margin: 8px;
            }

            &-day,
            &-month {
                font-weight: $font-weight-bold;
            }

            &-slash {
                font-weight: $font-weight-normal;
                margin: 0 -5px;
            }
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.5rem;
        }
    }

    &__template-btn {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    &__btn {
        margin: 0 15px;
        width: 270px;
    }
}

//======================================================================================================
// Documents
//======================================================================================================
.home-docs-img {
    padding: 80px 0 0;

    > .container {
        display: flex;
        justify-content: space-between;
    }
}

.home-documents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 150px;

    &__container {
        display: flex;
        align-items: center;
    }

    &__container-image {
        width: 175px;
        height: 250px;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
        border-radius: $border-radius;
        background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) no-repeat center $color-gray;
        overflow: hidden;
        margin-right: 30px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__content {
        max-width: 215px;
    }

    &__date {
        display: inline-block;
    }

    &__filesize {
        display: inline-block;

        span {
            text-transform: uppercase;
        }
    }

    &__doc-title {
        margin: 0 0 25px;
        font-size: $font-size--4;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    &__btn {
        display: flex;
        align-items: center;
        margin-top: 15px;
        color: $color-text;
        text-transform: uppercase;
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        line-height: 1;

        &:hover {
            color: $color-text;

            span {
                &:after {
                    width: calc(100% + 10px);
                }
            }
        }

        svg {
            fill: $color-fifth;
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        span {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                background-color: $color-fifth;
                left: -5px;
                height: calc(100% + 5px);
                width: 0;
                transition: .35s;
                z-index: -1;
                top: 0;
                bottom: 0;
                border-radius: $border-radius;
                margin: auto;
            }
        }
    }

    &__template {
        width: 420px;
        margin-top: 40px;

        &-btn {
            width: 100%;
            background-color: $color-fifth;
            border-color: $color-fifth;
            margin: 0;

            &:hover {
                border-color: $color-fifth;
            }
        }
    }
}


//======================================================================================================
// Retour en images
//======================================================================================================
.admin-area {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    flex: 1;
    
    &__title {
        position: absolute;
        width: 250px;
        right: 0;
    }
}

.admin-blocs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    max-height: 600px;
    align-items: flex-end;

    .bloc {
        display: flex;
        width: 250px;
        height: 148px;
        margin-left: 30px;
        margin-top: 30px;
        border-radius: $border-radius;

        &:nth-child(1) {
            margin-top: 0;
        }

        &:nth-child(1),
        &:nth-child(2) {
            width: 290px;
            height: 210px;
        }

        &__wrapper {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;


            &:hover {
                .bloc__content {
                    opacity: 1;
                }
            }

            &--video {
                cursor: pointer;

                &::before {
                    content: '\f126';
                    font: normal 34px/1 dashicons;
                    position: absolute;
                    z-index: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    color: $color-white;
                    transition: $duration;
                }

                &:hover {
                    &:before {
                        color: $color-black;
                    }
                }
            }

        }

        &__image {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &__content {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            opacity: 0;
            padding: 0 20px;
            background: rgba(65,193,190,0.85);
            border-radius: $border-radius;
            color: $color-black;
            transition: all 0.5s;
        }

        &__title {
            font-size: $font-size--text-medium ;
            text-align: center;
            margin: 0;
        }

        .popup-area {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 20;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            background: $color-black--rgba;

            &.show-popup {
                visibility: visible;
                opacity: 1;
            }

            .accordion {
                height: 0;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }

            .close-area-popup {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: absolute;
                top: 40px;
                right: calc((100% - 1170px)/2);
                width: 40px;
                height: 40px;
                background: $color-white;
                border-radius: 50px;

                &::before {
                    content: '\f335';
                    font: normal 30px/1 'dashicons';
                }
            }
        }
    }
}

//======================================================================================================
// Infos pratiques
//======================================================================================================
.home-infos {
    position: relative;
    padding-top: 90px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        background-color: $color-light;
        height: calc(100% + 20px);
        z-index: -1;
    }

    &:before {
        left: 50%;
        transform: translateX(-50%);
        width: 1710px;
        border-radius: 400px 0px 0px 0px;
    }

    &:after {
        left: calc((100% + 1170px) / 2);
        width: 2000px;
    }

    &__title {
        text-align: center;
    }

    &__list {
        display: flex;
        justify-content: space-between;
    }

    &__wrapper {
        position: relative;
        width: 270px;
        height: 95px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 30px;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            height: 100%;
            width: 100%;
            border-radius: $border-radius $border-radius 0 0;
            background-color: $color-third;
            z-index: -1;
            transition: .35s;
        }

        &:hover {
            &:before {
                height: calc(100% + 20px);
                background-color: $color-fourth;
            }

            .home-infos {
                &__text {
                    color: $color-white;
                    margin-bottom: 25px;
                }
            }
        }
    }

    &__text {
        margin-bottom: 5px;
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--4;
        color: $color-black;
        text-align: center;
        line-height: 1;
        transition: $duration;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .front-page {
        h2 {
            font-size: 2.75rem;
        }
    }

    .home-access-alu {
        &:before,
        &:after {
            height: calc(100% - 30px);
        }

        &:before {
            border-radius: 340px 0px 0px 0px;
            width: 1400px;
        }

        &:after {
            left: calc((100% + 940px) / 2);
        }
    }

    .home-slideshow {
        &__nav {
            &-prev {
                right: 95px;
            }
    
            &-next {
                right: 30px;
            }
        }
    
        .slideshow {
            height: 465px;
            width: 640px;
    
            &__content {
                padding: 25px 160px 25px 20px;
            }
        }
    }
    
    .home-access {
        width: calc(100% - 640px);

        &__svg-container {
            width: 46px;   
             height: 55px;

             svg {
                 width: 100%;
                 height: auto;
             }
        }

        &__label {
            font-size: $font-size--text-small;
            line-height: 1.125rem;
        }

        &__link {
            margin: 10px 10px 0 0;
        }
    }

    .home-posts {
        .featured-post {
            &__image-container {
                height: 180px;
            }
        }
    }

    .home-events {    
        &:before {
            border-radius: 0 0 0 340px;
            width: 1400px;
        }

        &:after {
            left: calc((100% + 940px) / 2);
        }

        .featured-event {
            margin-bottom: 20px;
            width: 460px;
    
            &--big {
                height: 424px;
                margin-right: 20px;
                box-shadow: none;
    
                .featured-event {
                    &__content {
                        padding: 45px 25px 25px;
                    }
                }
            }
    
            &--small {
                height: 128px;
    
                .featured-event {
                    &__date {
                        width: 128px;
                        height: 128px;
                    }

                    &__infos {
                        padding: 0 0 0 20px;
                    }
                }
            }
        }
    
        &__template-btn {
            margin-top: 20px;
        }
    
        &__btn {
            margin: 0 10px;
        }
    }
    
    .home-docs-img {
        padding: 70px 0 0;

        h2 {
            margin: 10px 0 20px;
        }
    }
    
    .home-documents {
        margin-right: 45px;

        &__container-image {
            width: 140px;
            height: 200px;
            margin-right: 25px;
        }
    
        &__template {
            width: 380px;
        }
    }

    .admin-area {        
        &__title {
            width: 220px;
        }
    }
    
    .admin-blocs {
        max-height: 520px;

        .bloc {
            width: 220px;
            height: 130px;
            margin-left: 20px;
            margin-top: 25px;
    
            &:nth-child(1),
            &:nth-child(2) {
                width: 255px;
                height: 185px;
            }
        }
    }

    .home-infos {
        &:before {
            border-radius: 340px 0 0 0;
            width: 1400px;
        }

        &:after {
            left: calc((100% + 940px) / 2);
        }

        &__wrapper {
            width: 220px;
            height: 90px;
            padding: 0 20px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .front-page {
        h2 {
            font-size: 3.125rem;
        }
    }

    .home-access-alu {
        padding-bottom: 30px;

        &:before {
            border-radius: 400px 0px 0px 0px;
            width: 1095px;
        }

        &:after {
            left: calc((100% + 620px) / 2);
        }

        > .container {
            flex-direction: column-reverse;
            align-items: center;
        }
    }

    .home-slideshow {

        .slideshow {
            height: 470px;
            width: 620px;

            &--video {
                video {
                    display: none;
                }
                img {
                    display: block;
                }
            }
        }
    }

    .home-access {
        width: 100%;

        &__svg-container {
            width: 50px;   
             height: 60px;
        }

        &__list {
            justify-content: space-between;
        }

        &__link {
            margin: 50px 25px 20px;
        }

        &__label {
            font-size: 1rem;
            line-height: 1.25rem;
        }
    }

    .home-posts {
        &__template-btn {
            justify-content: space-between;
        }

        &__btn {
            width: 300px;
            margin: 0;
        }
    }

    .home-events {  
        &:before {
            border-radius: 0px 0px 0px 400px;
            width: 1095px;
        }

        &__container {
            max-height: 100%;
        } 

        .featured-event {
            width: 100%;
    
            &--big {
                height: 410px;
                margin-right: 0;
    
                .featured-event {
                    &__content {
                        padding: 45px 30px 30px;
                    }
                }
            }
        }
    
        &__template-btn {
            margin-top: 20px;
            justify-content: space-between;
        }
    
        &__btn {
            margin: 0;
            width: 300px;
        }
    }

    .home-docs-img {
        > .container {
            flex-direction: column;
        }
    }

    .home-documents {
        position: relative;
        margin-right: 0;
        padding-bottom: 130px;
        align-items: flex-end;

        &__title {
            width: 320px;
        }

        &__container {
            justify-content: flex-start;
            width: 320px;
        }

        &__container-image {
            width: 255px;
            height: 365px;
            position: absolute;
            left: 0;
            top: 0;
        }

        &__doc-title {
            margin: 0 0 10px;
        }

        &__content {
            max-width: 100%;
        }

        &__template {
            width: 320px;
            margin-top: 30px;
        }
    }

    .admin-area {        
        &__title {
            width: 275px;
            top: 10px;
        }
    }
    
    .admin-blocs {
        max-height: 660px;

        .bloc {
            width: 275px;
            height: 165px;
            margin-left: 0;
            margin-top: 20px;
    
            &:nth-child(1),
            &:nth-child(2) {
                width: 325px;
                height: 235px;
                margin-right: 20px;
            }
        }
    }

    .home-infos {
        padding-top: 100px;

        &::before,
        &::after {
            display: none;
        }
    
        &__list {
            flex-wrap: wrap;
        }
    
        &__wrapper {
            width: 300px;
            margin-top: 20px;

            &:nth-child(-n+2) {
                &:before {
                    border-radius: $border-radius;
                }
            }
    
            &:hover {
                &:before {
                    height: 100%;
                }

                .home-infos {
                    &__text {
                        margin: 0;
                    }
                }
            }
        }
    
        &__text {
            margin: 0;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .front-page {
        h2 {
            font-size:2.375rem;
            text-align: center;
    
            span {
                font-size: 1.5rem;
            }
        }
    }

    .home-access-alu {
        padding-bottom: 60px;
        background-color: $color-light;

        &::before,
        &::after {
            display: none;
        }

        > .container {
            max-width: 100%;
            padding: 0;
        }
    }

    .home-slideshow {
        width: 100%;
        border-radius: 0;

        &__nav-next,
        &__nav-prev {
            bottom: 25px;
            right: auto;
        }
        &__nav-next {
            left: calc(((100% - 300px) / 2) + 60px);
        }

        &__nav-prev {            
            left: calc((100% - 300px) / 2);
        }

        .slideshow {
            width: 100%;

            &__content {
                padding: 25px 0 70px;
            }

            &__title {
                width: 300px;
                margin: 0 auto 5px;
            }

            &__description {
                width: 300px;
                margin: 0 auto;
            }
        }
    }

    .home-access {
        max-width: 320px;
        padding: 0 10px;
        margin: 0 auto;

        &__link {
            margin: 50px 0px 0;
        }
    }

    .home-posts {
        &__template-btn {
            flex-direction: column;
            margin-top: 10px;
        }

        &__btn {
            margin-top: 20px;
        }
    }

    .home-events {   
        &:before {
            border-radius: 0;
            width: 100%;
        }

        .featured-event {
            height: auto;

            &__image-container {
                height: 250px;
            }
  
            &--big {
                flex-direction: column;
    
                .featured-event {
                    &__content {
                        position: relative;
                        padding: 42px 20px 20px;

                        &::after {
                            opacity: 1;
                        }
                    }

                    &__date {
                        > p {
                            display: flex;
                        }

                        &-slash {
                            margin: 0 2px;
                        }
                    }
                }
            }

            &--small {
                .featured-event {
                    &__date {
                        width: 100%;
                        height: 70px;
                        flex-direction: row;

                        > svg {
                            transform: rotate(-90deg);
                            margin: 0 12px;
                       }
                    }

                    &__content {
                        flex-direction: column;
                    }

                    &__infos {
                        padding: 20px 0;
                        text-align: center;
                    }

                    &__title {
                        &:after {
                            margin: 8px auto 0;
                        }
                    }
                }
            }
        }
    
        &__template-btn {
            margin-top: 0;
            justify-content: space-between;
            flex-direction: column;
        }
    
        &__btn {
            margin: 20px auto 0;
            width: 300px;
        }
    }

    .home-docs-img {
        padding: 40px 0 0;

        h2 {
            margin: 0 0 30px;
        }
    }

    .home-documents {
        padding-bottom: 100px;

        &__title {
            width: 100%;
        }

        &__container {
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 100%;
        }

        &__container-image {
            position: relative;
            margin: 0 auto 45px;
        }

        &__content {
            max-width: 100%;
            padding: 0 10px;
        }

        &__buttons {
            align-items: center;
        }

        &__template {
            width: 100%;
        }
    }

    .admin-area {
        flex-direction: column;

        &__title {
            width: 100%;
            position: relative;
            top: 0;
        }
    }

    .admin-blocs {
        max-height: 100%;

        .bloc {
            width: 100%;
            height: 215px;
           
            &:first-child, 
            &:nth-child(2) {
                width: 100%;
                margin-right: 0;
                height: 215px;
            }
        }
    }

    .home-infos {
        &__wrapper {
            margin-top: 0;
            margin-bottom: 15px;

            &:before {
                border-radius: $border-radius;
            }

            &:last-child {
                margin-bottom: 0;

                &:before {
                    border-radius: $border-radius $border-radius 0 0;
                }
            }
    
            &:hover {
                &:before {
                    height: 100%;
                }
            }
        }
    }

}
