#nav-main {
    position: relative;
    padding-left: 370px;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            height: 70px;
            padding: 0;
            color: $color-dark;
            text-transform: uppercase;
            font-family: $font-family--heading;
            font-size: 1.75rem;
            font-weight: $font-weight-semibold;

            > span {
                font-weight: $font-weight-normal;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                height: 0;
                background: $color-second;
                border-radius: $border-radius $border-radius 0 0;
                transition: $duration;
            }

            &:hover {
                &:after {
                    height: 10px;
                }
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 30px 0 10px;
            z-index: 15;

            &:before {
                content: "";
                width: 5000px;
                height: 100%;
                top: 0;
                position: absolute;
                background-color: $color-white;
                left: -2000px;
                box-shadow: $shadow--variant;
            }

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                padding: 0;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    height: 110px;
                    padding: 0 25px 0 15px;
                    color: $color-text;
                    background-color: $color-light;
                    border-radius: $border-radius;
                    margin-bottom: 20px;

                    &:hover {
                        background-color: $color-main;
                    }
                }

                &__title {
                    font-weight: $font-weight-bold;
                    margin: 0 0 5px;
                }

                &__intro {
                    font-size: $font-size--text-small;
                }
            }

            .nav-grandchild-item {
                padding-left: 30px;

                a {
                    display: flex;
                    padding: 12px 25px 12px 15px;
                    height: auto;
                    border-bottom: 0;
                    font-size: $font-size--text;
                    font-weight: $font-weight;
                    text-transform: initial;
                    line-height: initial;

                    &::before {
                        display: none;
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 0;
    color: $color-text;

    .navicon {
        position: relative;
        display: block;
        height: 4px;
        top: -1px;
        width: 33px;
        margin-right: 20px;
        background: $color-second;
        border-radius: $border-radius;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-second;
            transition: all $duration ease-out;
        }

        &:before {
            top: 9px;
        }

        &:after {
            top: -9px;
        } 
    }
}

.header {
    &--fixed {
        #nav-main { 
            padding-left: 185px;
            padding-right: 185px;
        }
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        padding-left: 300px;
       
        .nav-main-item {
            .nav-main-link {
                font-size: 1.5rem;
            }
        }
    }

    .header {
        &--fixed {
            #nav-main { 
                padding-left: 120px;
                padding-right: 120px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: relative;
        padding-left: 240px;

        .container--nav {
            position: relative;
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 105px;
            width: 100%;
            margin: auto -15px;
            background: $color-white;
            z-index: 12;

            &:before {
                content: "";
                width: 2000px;
                height: 100%;
                top: 0;
                position: absolute;
                background-color: $color-white;
                left: -500px;
                box-shadow: $shadow--variant;
            }
        }

        .nav-main-item {
            .sub-menu, 
            .sub-menu {
                .nav-child-item,
                .nav-grandchild-item {
                    a {
                        height: auto;
                        padding: 15px 25px 15px 15px;
                        margin: 0;
                        background-color: transparent;
                    }

                    &__intro {
                        display: none;
                    }
                }

                .nav-grandchild-item {
                    padding-left: 0;
                }
            }

            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 10px;
                border-bottom: 1px solid $color-second;
                color: $color-text;
                text-transform: uppercase;

                &::after {
                    display: none;
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {
                background: $color-bg--neutral;
                box-shadow: 0 0 1px rgb(27 35 54 / 8%), 1px 1px 10px rgb(27 35 54 / 8%);

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 0;
                    box-shadow: none;

                    &::before {
                        display: none;
                    }

                    .nav-drop {
                        column-count: 1;
                        background-color: $color-light;
                    }

                    .nav-grandchild-item {
                        a {
                            padding: 5px 10px 5px 30px;
                        }
                    }

                    li:last-child {
                        a {
                            border-bottom: 0;

                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
                left: 0;
                right: 0;
                margin: auto;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 105px;
            margin: 0;
            padding: 0;
            font-family: $font-family--heading;
            font-size: 2.5rem;
            font-weight: $font-weight--heading;
            text-transform: uppercase; 
        }
    }
    
}


// 640
@media screen and (max-width: $small) {
    #nav-main {
        padding-left: 0;
    }
}
