.header {
    position: relative;

    &__identity {
        position: absolute;
        display: flex;
        top: 10px;
        left: calc(((100% - 1170px) / 2) + 55px);
        z-index: 5;
        background-color: $color-white;
        transition: $duration;

        &__title {
            margin: 0;
            font-size: 0;
            line-height: 0;

            &::after {
                display: none;
            }

            .identity-logo {
                display: flex;
                height: 132px;
                width: auto;
            }

            .identity-logo-fixed {
                display: none;
                fill: $color-white;
            }
        }
    }

    &__bottom {
        transition: $duration;
        background-color: $color-white;
        z-index: 11;
    }

    &--fixed {
        .header {
            &__identity {
                width: 50px;
                height: 50px;
                background: $color-second;
                border-radius: $border-radius;
                border: 2px solid $color-second;
                top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background-color: $color-main;
                    border-color: $color-main;

                    .header__identity__title .identity-logo-fixed path {
                        fill: $color-dark;
                    }
                }

                &__title {
                    .identity-logo {
                        display: none;
                    }

                    .identity-logo-fixed {
                        display: flex;
                    }
                }
            }

            &__bottom {
                position: fixed;
                top: 0;
                width: 100%;
                height: 70px;
                box-shadow: $shadow--variant;
            } 
        }

        .tools {
            &__search {
                position: fixed;
                right: calc((100% - 1170px) / 2);
                top: 10px;
                width: 50px;
                height: 50px;
                border-radius: $border-radius;
                z-index: 12;

                svg {
                    margin-right: 0;
                    width: 21px;
                    height: 22px;
                }

                span {
                    display: none;
                }
            }
        }
    }
} 



//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {
    padding-left: 370px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 12px;
    transition: $duration;

    &__content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        &:after {
            content: "";
            position: absolute;
            width: calc(100% - 20px);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $color-main;
        }
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 1rem;

        &:hover {
            cursor: pointer;
        }
    }

    &__accessibility {
        border: 0;
        margin: 0;
        color: $color-second;
        background-color: transparent;

        &:hover {
            color: $color-black;

            svg {
                fill: $color-black;
    
            }
        }

        svg {
            margin-right: 10px;
            width: 21px;
            height: 18px;
            fill: $color-second;

        }
    }

    &__fb {
        border: 0;
        margin: 0 30px 0 40px;

        &:hover {
            svg {
                fill: $color-black;
    
            }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: $color-second;
        }
    }

    &__search {
        margin: 0;
        width: 245px;
        height: 40px;
        color: $color-text;
        border-radius: $border-radius $border-radius $border-radius 0;
        border: 2px solid $color-main;

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        &:hover {
            background-color: $color-white;
            border: 2px solid $color-main;
        }

        svg {
            margin-right: 10px;
            width: 13px;
            height: 14px;
            fill: $color-dark;
        }
    }
}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        color: $color-black;

        &:hover {
            border-color: $color-white;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 230px!important;
    }

    .header {
        &__identity {
            top: 10px;
            left: calc(((100% - 940px) / 2) + 55px);

            &__title {
                .identity-logo {
                    height: 115px;
                }
            }
        }

        &--fixed {
            .header {
            }
    
            .tools {
                &__search {
                    right: calc((100% - 940px) / 2);
                }
            }
        }
    }

    .tools {
        padding-left: 300px;
    }

}


// 960
@media screen and (max-width: $medium) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 240px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .header {
        &__identity {
            top: 10px;
            left: calc(((100% - 620px) / 2) + 10px);

            &__title {
                .identity-logo {
                    height: 115px;
                }
            }
        }
    }

    .tools {
        padding-left: 240px;

        &__content {
            justify-content: space-between;
        }

        &__accessibility {
            span {
                display: none;
            }
        }

        &__fb {
            margin: 0 30px 0 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 280px!important;
    }
    .header {
        &__identity {
            top: 25px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .tools {
        margin: auto;
        padding-top: 165px;
        justify-content: center;
        padding-left: 0;

        &__accessibility {
            svg {
                width: 30px;
                height: 23px;
            }
        }

        &__search {
            width: 160px;
            height: 50px;

            &__text-snd {
                display: none;
            }
        }

        &__fb {
            svg {
                width: 25px;
                height: 25px;
            }
        }
    }

    .tools-view {
        .container--tools-view {
            .profils {
                margin-top: 80px;
                margin-right: 35px;
            }
        }
    }

}
