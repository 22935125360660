.search-container {
    display: flex;
    position: relative;
    align-items: center;

    .searchInput {
        padding: 24px 40px 24px 24px;
        border-color: $color-white!important;
        background-color: $color-white;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        box-shadow: $shadow--variant;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }

    .search-svg {
        width: 48px;
        height: 48px;
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: $color-main;
        border-radius: $border-radius;
        border: 2px solid $color-main;
        transition: $duration;

        svg {
            width: 15px;
            height: 15px;
            fill: $color-text;
            transition: all $duration;
        }

        &:hover {
            background-color: $color-white;
        }
    }
}

.searchResult {
    position: relative;
    top: -2px;
    display: flex;
    flex-direction: column;
    background: $color-white;
    border-top: 1px solid $color-gray;

    li {
        position: relative;
        background: $color-white;
        border-bottom: 1px solid $color-bg--neutral;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 24px;
            transition: all $duration;

            &:focus {
                color: $color-text;
            }
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            color: $color-text;
            transform: translate(0,-50%);
            transition: all $duration;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 24px;
                color: $color-text;
            }
        }

        &:not(.no-results) {
            a:hover {
                padding-left: 35px;
                background: $color-main;
                color: $color-text;

                &::after {
                    color: $color-text;
                }
            }

            &.selected,
            &:hover {
                .resultCPT {
                    color: $color-text;
                }
            }
        }

        &:last-child {
            border-bottom:0;
        }

        &.selected {
            a {
                padding-left: 35px;
                background: $color-main;
                color: $color-text;

                &::after {
                    color: $color-text;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 18px/1 dashicons;
                color: $color-text;
                transition: all $duration;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 0;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 25;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: $color-bg--neutral;
    transition: all 0.65s;

    .container {
        position: relative;
        height: 100%;
        

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;   
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 20px auto;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family--heading;
            font-size: $font-size--1;
            font-weight: $font-weight--heading;
            color: $color-text;
            text-transform: none;
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;

            .searchInput {
                background: $color-white;
                border-color: $color-white;

                &:hover, &:focus {
                    border-color: $color-white;
                }
            }
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
        }
    }

    .search-close {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 0;
        width: 65px;
        height: 65px;
        padding: 0;
        background: $color-second;
        border-color:  $color-second;
        border-radius: 50%;
        transition: all $duration;
        
        svg {
            width: 35px;
            height: 35px;
            fill: $color-white;
            transition: all $duration;
        }

        &:hover {
            background: $color-white;
            svg {
                fill: $color-main;
            }
        }
    }
}


//======================================================================================================
// Search Page
//======================================================================================================
.filter {
    .search-container {
        .search-svg {
            background: $color-main;
            transition: all $duration;

            &:hover {
                background: $color-white;
            }

            svg {
                fill: $color-text;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }

}


// 960
@media screen and (max-width: $medium) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }

}


// 640
@media screen and (max-width: $small) {

    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        font-size: 2.5em;
    }

}
