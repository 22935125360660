.footer {
    box-shadow: $shadow--variant;
    background-color: $color-white;
    
    &-top {
        position: relative;
        padding-top: 60px;

        > .container {
            position: relative;
            z-index: 1;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 25px;
            width: 100%;
            background-color: $color-light;
            z-index: 0;
        }

        > svg {
            position: absolute;
            bottom: 25px;
            width: 1442px;
            left: 50%;
            transform: translateX(-50%);
            fill: $color-light;
            z-index: 0;
        }
    }

    &-infos {
        display: flex;
        justify-content: space-between;

        &__address {
            width: 270px;
            margin-right: 30px;
        }

        &__hours {
            width: 570px;
        }

        &__logo {
            flex: 1;
            display: flex;
            justify-content: flex-end;

            &-link {
                margin-top: 10px;
                width: 200px;
                height: 46px;
                transform: scale(1);
                transition: $duration;

                &:hover {
                    transform: scale(.9);
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        &__title {
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;
            font-size: $font-size--4;
            margin: 0 0 10px;
        }

        &__phone {
            display: flex;
            align-items: center;
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;
            color: $color-second;
            margin-top: 8px;

            &:hover {
                span {
                    text-decoration: underline;
                }
            }

            span {
                transition: $duration
            }

            svg {
                fill: $color-second;
                margin-right: 10px;
                width: 17px;
                height: 17px;
            }
        }
    }

    &-links {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-top: 75px;

        &:after {
            content: '';
            position: absolute;
            width: calc(100% - 20px);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $color-main;
        }

        .button {
            padding: 0;
            height: 40px;
            margin: 0 0 0 30px;
        }

        &__contact {
            width: 235px;
            border-radius: $border-radius $border-radius 0 0;

            svg {
                width: 18px;
                height: 14px;
                margin-right: 20px;
            }
        }

        &__fb {
            width: 40px;
            border-radius: $border-radius $border-radius $border-radius 0;

            svg {
                width: 11px;
                height: 22px;
            }
        }
    }

    &-bottom {
        background-color: $color-light;
    }

    &__menu {
        &__list {
            display: flex;
            justify-content: space-between;
            padding: 12px 0 10px;
        }

        &__link {
            line-height: 2.5rem;

            &:hover {
                color: $color-black;
                text-decoration: underline;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {
    .footer {
        &-top {
            &:after {
                bottom: 0;
                height: 10px;
            }

            > svg {
                bottom: 10px;
                width: 1140px;
                height: 128px;
            }
        }

        &-infos {
            &__address {
                width: 245px;
                margin-right: 25px;
            }
    
            &__hours {
                width: 480px;
            }

            &__logo {
                &-link {
                    width: 160px;
                    height: 36px;
                }
            }
        }

        &-links {
            margin-top: 50px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &-top {
            > svg {
                width: 960px;
                height: 108px;
            }
        }

        &-infos {
            flex-wrap: wrap;
    
            &__address {
                order: 1;
            }
    
            &__hours {
                order: 3;
                margin-top: 30px;
            }

            &__logo {
                order: 2;

                &-link {
                    margin-top: 30px;
                }
            }
        }

        &-links {
            margin-top: 75px;
        }

        &__menu {
            &__list {
                gap: 0px 35px;
                flex-wrap: wrap;
                justify-content: flex-start;
            }
            
            &__link {
                font-size: $font-size--text-small;
                line-height: 2rem;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &-top {
            padding-top: 30px;

            &:after {
                height: 30px;
            }

            > svg {
                bottom: 30px;
                width: 640px;
                height: 72px;
            }
        }

        &-infos {
            text-align: center;
            justify-content: center;

            &__address {
                justify-content: center;
            }

            &__hours {
                order: 2;
            }

            &__phone {
                justify-content: center;
            }

            &__logo {
                order: 3;
                margin-top: 30px;
                justify-content: center;

                &-link {
                    margin-top: 0;
                }
            }
        }

        &-links {
            justify-content: space-between;

            &:after {
                width: calc(100% - 40px);
                left: 50%;
                transform: translateX(-50%);
            }

            .button {
                margin: 0;
            }
    
            &__contact {
                width: 230px;
                border-radius: $border-radius $border-radius 0 $border-radius;
            }
        }

        &__menu {
            &__list {
                justify-content: center;
            }
        }
    }
}