.breadcrumb {
    background-color: $color-white;
    box-shadow: $shadow--variant;
    position: relative;
    z-index: 1;

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 70px;
        padding: 15px 0;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-black;
        padding: 0;

        > svg {
            margin: 0 15px;
            fill: $color-main;
            width: 14px;
            height: 12px;
        }

        a,
        span {
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;
            font-size: $font-size--5;
            text-transform: uppercase;
            color: $color-black;
            text-decoration: none;
            transition: $duration;
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
